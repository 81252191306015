/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/

.privateComment {
/* border: 2px solid red !important;*/
border-radius: 10px; 
background-color: bisque!important;
}

.text-sivcom {
  color: #00A9BC !important;
}

a.text-sivcom:hover,
a.text-sivcom:focus {
  color: #E8CDA6 !important;
}