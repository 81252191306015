/* Set fixed Bootstrap Argon styles (avoid conflict with semantic-ui-react)  */

body {
  margin: 0 !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #525f7f !important;
  text-align: left !important;
  background-color: #f8f9fe !important;
}

.footer {
  background-color: #f8f9fe !important;
  padding: 2.5rem 0;
}

/* LOGO */

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 3rem;
  }
}

th.id-cell,
td.id-cell {
  width: 50px !important;
  max-width: 50px !important;
}

div.asset-detail {
  border: 1px solid #dcdce3;
}

table.asset-detail th {
  background-color: #ececf3 !important;
  border: 1px solid #dcdce3;
  width: 20em;
  max-width: 20em;
  white-space: normal;
}

.development {
  display: none;
}

/* ARGON REDEFINED STYLES */

strong {
  font-weight: bold;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #333;
  text-align: left;
  caption-side: top;
  padding-left: 2px;
}

/* React bootstrap table */

.row.react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

.react-bootstrap-table .table .thead-light th {
  background-color: #f6f9fc;
  color: #444c55;
}

.react-bootstrap-table > table > thead > tr > th .filter {
  font-weight: 400;
  height: 2.2rem;
}

/* CUSTOM STYLES */

.w-10 {
  max-width: 10em;
  width: 10em;
}

.w-15 {
  max-width: 15em;
  width: 15em;
}

.fondo {
  background-image: url("/assets/img/backgrounds/fondo3.jpg");
  background-size: cover;
  min-height: 80vh;
}
.fondoAdmin {
  background-image: url("/assets/img/backgrounds/fondo2.jpg");
  background-size: cover;
  min-height: 80vh;
}
.fondoLogin {
  background-image: url("/assets/img/backgrounds/fondo2.jpg");
  background-size: cover;
  min-height: 100vh;
}